import React, { useState, useEffect } from "react";
import axios from "axios";
import { Tree, TreeNode } from "react-organizational-chart";
import { FamilyMember } from "types";
import {
  Switch,
  makeStyles,
  Theme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { loginUser, logout, useAuthState, useAuthDispatch } from "../context";
import Node from "./Node";
import _, { indexOf } from "lodash";
import { useParams } from "react-router";

const styles = makeStyles((theme: Theme) => ({
  root: {},
  switchWrapper: {
    display: "flex",
    alignItems: "center",
    position: "fixed",
    bottom: 20,
    fontSize: 12,
    left: 20,
    zIndex: 2000000,
  },
  switchButton: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255, 0.3);",
    borderRadius: 5,
    padding: 4,
    marginRight: 8,
  },
  ramahiImg: {
    marginTop: 8,
    width: 128,
  },
  ramahiImgEnglish: {
    marginTop: 8,
    width: 275,
  },
  showControl: {
    display: "flex",
    width: 128,
    justifyContent: "space-between",
    marginLeft: 4,
  },
  showControlButtons: {
    cursor: "pointer",
    backgroundColor: "rgba(255,255,255, 0.3);",
    padding: 4,
    borderRadius: 5,
  },
  title: {
    "& div": {
      display: "flex",
      justifyContent: "center",
      fontWeight: 700,
      position: "relative",
    },
  },
  textField: {
    width: 280,
    marginRight: 8,
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
}));

const toArabicDigits = (w: string) => {
  var id = ["۰", "۱", "۲", "۳", "٤", "٥", "٦", "۷", "۸", "۹"];
  return w.replace(/[0-9]/g, function (w) {
    return id[+w];
  });
};

const FamilyTree = () => {
  const classes = styles();
  const [lang, setLang] = useState("ar");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [ids, setId] = useState<(string | number)[]>([]);
  const [count, setCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const userDetails = useAuthState();

  const { user = {} } = userDetails;

  const dispatch = useAuthDispatch();

  const updateData = (
    member: {
      name: string;
      arabicName: string;
      canEdit: boolean;
      canAdd: boolean;
      lineage: string;
    },
    parentId: number | string,
    updateFinish: () => void
  ) => {
    axios
      .post("https://sheltered-ridge-72729.herokuapp.com/families", {
        ...member,
        parent: [parentId],
      })
      .then((res) => {
        getBranchCount();
        updateFinish();
      });
  };

  const editData = (
    id: number | string,
    member: {
      name: string;
      arabicName: string;
      canEdit: boolean;
      canAdd: boolean;
      lineage: string;
    },
    setFinished: () => void
  ) => {
    axios
      .put(`https://sheltered-ridge-72729.herokuapp.com/families/${id}`, {
        ...member,
      })
      .then((res) => {
        setFinished();
      });
  };

  const getBranchCount = () => {
    axios
      .get(
        "https://sheltered-ridge-72729.herokuapp.com/families/count?branch=Wishah"
      )
      .then((res) => {
        setCount(res.data as number);
      });
  };
  const getTotalCount = () => {
    axios
      .get(
        "https://sheltered-ridge-72729.herokuapp.com/families/count?[lineage_contains]=Ramahi"
      )
      .then((res) => {
        setTotalCount(res.data as number);
      });
  };

  const login = async (e: any) => {
    e.preventDefault();
    const payload = {
      identifier: userName,
      password: password,
    };

    try {
      let response = await loginUser(dispatch, payload);
      if (!response.user) return;
      setShowLogin(false);
      setUserName("");
      setPassword("");
      setOpenAlert(true);
      setAlertMessage("Successfully logged in.");
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = () => {
    logout(dispatch);
    setOpenAlert(true);
    setAlertMessage("Logged out.");
  };

  React.useEffect(() => {
    getTotalCount();
    getBranchCount();
  }, []);

  const { id } = useParams<{ id: string | undefined }>();

  console.log(id)

  return (
    <>
      <Tree
        label={
          <div>
            {lang === "en" ? (
              <div>
                <img
                  className={classes.ramahiImgEnglish}
                  src="/ramahiEnglish.png"
                  alt="Al Ramahi"
                />
              </div>
            ) : (
              // <img className={classes.ramahiImg} src="/ramahi.png" alt="" />
              <div>
                <img className={classes.ramahiImg} src="/ramahi.svg" alt="" />
              </div>
            )}
          </div>
        }
        lineColor={"white"}
      >
        <Node
          memberId={parseInt(id || "")}
          setData={updateData}
          isArabic={lang === "ar"}
          ids={ids}
          setId={setId}
          editUser={editData}
          length={count.toString()}
          lineage=""
          arabicLineage=""
          color="#9575cd"
          singleNode
          branch=""
        />
      </Tree>
      <div className={classes.switchWrapper}>
        <div className={classes.switchButton}>
          <div>AR</div>
          <Switch
            checked={lang === "en"}
            onChange={() => (lang === "en" ? setLang("ar") : setLang("en"))}
            size="small"
          />
          <div>EN</div>
        </div>
        <div className={classes.switchButton}>
          {Object.values(user).length > 0 ? (
            <Button onClick={handleLogout}>Logout</Button>
          ) : (
            <Button onClick={() => setShowLogin(true)}>Login</Button>
          )}
        </div>
        <Dialog
          open={showLogin}
          onClose={() => {
            setShowLogin(false);
            setUserName("");
            setPassword("");
          }}
          className={classes.root}
        >
          <DialogTitle className={classes.title}>Login</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <TextField
              id="outlined-basic"
              label="Username:"
              variant="outlined"
              size="small"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className={classes.textField}
            />
            <TextField
              id="outlined-basic"
              label="Password:"
              variant="outlined"
              size="small"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classes.textField}
              type="password"
            />
            <Button onClick={login}>Login</Button>
          </DialogContent>
        </Dialog>
        <Snackbar
          open={openAlert}
          autoHideDuration={3000}
          onClose={() => setOpenAlert(false)}
        >
          <Alert severity="success" color="info">
            {alertMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default FamilyTree;
