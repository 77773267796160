import React from 'react';
import { makeStyles, Theme, Hidden } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const styles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
    },
    routeList: {
        display: "flex",
        width: "60%",
        minWidth: "320px",
        margin: "auto",
        flexDirection: "row",
        listStyle: "none",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 0,
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column"
        }
    },
    links: {
        display: "flex",
        flexDirection: "column",
        width: 640,
        marginLeft: 8,
        alignItems: "center",
        textDecoration: "none",
        color: "white",
        border: "1px solid white",
        padding: 8,
        borderRadius: 6,
        [theme.breakpoints.down("sm")]: {
            marginBottom: 8,
            width: 320,
        }
    },
    ramahiImg: {
        marginTop: 8,
        width: 128,
      },
}));

function Home() {
    const classes = styles();

    const book1 = ""
    return (
        <div className={classes.root}>
            <img className={classes.ramahiImg} src="/ramahi.svg" alt="" />
            <ul className={classes.routeList}>
                <Link className={classes.links} to="/tree">
                    <span>شجرة العائلة</span>
                    <span>Family Tree</span>
                </Link>
                <Hidden smDown>
                    <Link className={classes.links} to="/AlMuzayria">
                        <span>كتاب المزيرعة</span>
                        <span>Al Muzayri'a Book</span>
                    </Link>
                    <Link className={classes.links} to="/DaysFromMyLife">
                        <span>كتاب أيام من عمري</span>
                        <span>Days From My Life Book</span>
                    </Link>
                    <a className={classes.links} href="https://www.palestineremembered.com/al-Ramla/al-Muzayri%27a/ar/index.html?fbclid=IwAR3vstCMA6kAb4mZ0mHCT0enR0gXn6Pz5GoaMVKGu0IWLxjN_XC-SYfoXfQ" target="_blank">
                        <span>معلومات عن المزيرعة</span>
                        <span>Information About Al Muzayri'a</span>
                    </a>
                    <a className={classes.links} href="https://www.youtube.com/watch?v=ppw6AtGTX3I" target="_blank">
                        <span>فيلم عن عائلة الرمحي</span>
                        <span>Film About The Ramahi Family</span>
                    </a>
                </Hidden>
                <Hidden mdUp>
                    <a className={classes.links} href="/pdfs/AlMuzayria.pdf" download>
                        <span>كتاب المزيرعة</span>
                        <span>Al Muzayri'a Book</span>
                    </a>
                    <a className={classes.links} href="/pdfs/DaysFromMyLife.pdf" download>
                        <span>كتاب أيام من عمري</span>
                        <span>Days From My Life Book</span>
                    </a>
                    <a className={classes.links} href="https://www.palestineremembered.com/al-Ramla/al-Muzayri%27a/ar/index.html?fbclid=IwAR3vstCMA6kAb4mZ0mHCT0enR0gXn6Pz5GoaMVKGu0IWLxjN_XC-SYfoXfQ" target="_blank">
                        <span>معلومات عن المزيرعة</span>
                        <span>Information About Al Muzayri'a</span>
                    </a>
                    <a className={classes.links} href="https://www.youtube.com/watch?v=ppw6AtGTX3I" target="_blank">
                        <span>فيلم عن عائلة الرمحي</span>
                        <span>Film About The Ramahi Family</span>
                    </a>
                </Hidden>
            </ul>
        </div>
    )
}

export default Home
