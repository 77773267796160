import React from "react";
import { FamilyMember } from "types";
import {
  makeStyles,
  Theme,
  Card,
  CardHeader,
  CardActions,
  CircularProgress,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import Profile from "./Profile";
import { TreeNode } from "react-organizational-chart";
import axios from "axios";

const toArabicDigits = (w: string) => {
  var id = ["۰", "۱", "۲", "۳", "٤", "٥", "٦", "۷", "۸", "۹"];
  return w.replace(/[0-9]/g, function (w) {
    return id[+w];
  });
};

const styles = makeStyles((theme: Theme) => ({
  root: {
    width: "fit-content",
    margin: "0 auto",
    cursor: "pointer",
    position: "relative",
    overflow: "visible !important",
    backgroundColor: "transparent",
    color: "white !important",
    boxShadow: "none",
    "&:hover > #actions, #actons:hover": {
      transform: "translateX(22px)",
      transition: "0.5s ease-in-out",
      zIndex: 10,
    },
    "& .MuiCardHeader-root": {
      padding: 8,
    },
    "& .MuiCardActions-root": {
      padding: "0 !important",
      justifyContent: "center",
      "&:hover": {
        backgroundColor: "gray",
      },
    },
  },
  cardHeader: {
    "& .MuiCardHeader-action": {
      margin: "0 !important",
    },
    "& .MuiCardHeader-title": {
      whiteSpace: "nowrap",
      fontWeight: 600,
      fontSize: 20,
    },
    "& .MuiTypography-body2": {
      whiteSpace: "nowrap",
      fontSize: 16,
    },
  },
  addButton: {
    padding: 4,
    minWidth: 16,
    borderRadius: 15,
    marginTop: 4,
    "& .MuiSvgIcon-root": {
      fontSize: 12,
    },
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: -10,
    paddingLeft: 8,
  },
  arabicStyles: {
    "& .MuiCardHeader-title": {
      whiteSpace: "nowrap",
      fontWeight: 600,
      fontSize: 20,
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& div": {
      fontSize: 14,
    },
  },
}));

interface OwnProps {
  member?: FamilyMember;
  memberId?: number;
  setData: (
    member: {
      name: string;
      arabicName: string;
      canEdit: boolean;
      canAdd: boolean;
      lineage: string;
    },
    parentId: number | string,
    setFinished: () => void
  ) => void;
  isArabic: boolean;
  setId: (id: any) => void;
  ids: any;
  editUser: (
    id: number | string,
    member: {
      name: string;
      arabicName: string;
      canEdit: boolean;
      canAdd: boolean;
      lineage: string;
    },
    setFinished: () => void
  ) => void;
  length: string;
  lineage: string;
  arabicLineage: string;
  color: string;
  singleNode?: boolean;
  branch: string;
}

type Props = OwnProps;

const Node = (props: Props) => {
  const {
    // member,
    memberId,
    setData,
    isArabic,
    setId,
    ids,
    editUser,
    length,
    lineage,
    arabicLineage,
    color,
    singleNode,
    branch
  } = props;
  const [openAddDialog, setOpenAddDialog] = React.useState(false);
  const [count, setCount] = React.useState("0");
  const [member, setMember] = React.useState<FamilyMember>({} as FamilyMember);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [edited, setEdited] = React.useState<boolean>(true);
  const classes = styles();
  React.useEffect(() => {
    const n = document.getElementById(`${member?.id}card`);
    n?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }, [member?.id]);

  const familyColor = color;

  const handleEdit = (
    id: number | string,
    member: {
      name: string;
      arabicName: string;
      canEdit: boolean;
      canAdd: boolean;
      lineage: string;
    }
  ) => {
    editUser(id, member, () => setEdited(true));
  };

  const handleSet = (
    member: {
      name: string;
      arabicName: string;
      canEdit: boolean;
      canAdd: boolean;
      lineage: string;
    },
    parentId: number | string
  ) => {
    setData(member, parentId, () => setEdited(true));
  };

  React.useEffect(() => {
    if (edited) {
      axios
        .get(`https://sheltered-ridge-72729.herokuapp.com/families/${memberId}`)
        .then((res: any) => {
          const family: FamilyMember = res.data;
          if (
            family.parent.length > 0 &&
            family.lineage !==
              `${family.parent[0].name} ${family.parent[0].lineage}`
          ) {
            editUser(
              family.id,
              {
                ...family,
                lineage: `${family.parent[0].name} ${family.parent[0].lineage}`,
              },
              () => setEdited(true)
            );
          }

          setMember(family);
        });
      setEdited(false);
    }
  }, [edited]);
  React.useEffect(() => {
    if (Object.values(member).length > 0) {
      axios
        .get(
          `https://sheltered-ridge-72729.herokuapp.com/families/count?[lineage_contains]=${
            member.name + " " + (singleNode ? member.lineage : lineage)
          }`
        )
        .then((res: any) => {
          const num = res.data;
          setCount(num);
        });
    }
  }, [member]);
  return Object.values(member).length > 0 ? (
    <TreeNode
      label={
        <>
          <Profile
            open={openAddDialog}
            setOpen={(open: boolean) => setOpenAddDialog(open)}
            member={member}
            name={isArabic ? member.arabicName : member.name}
            lineage={singleNode ? member.lineage : lineage}
            arabicLineage={
              singleNode ? member.arabicLineage || "" : arabicLineage
            }
            isArabic={isArabic}
            setData={handleSet}
            editUser={handleEdit}
            branch={branch}
          />
          <Card className={classes.root}>
            <CardHeader
              title={
                isArabic ? (
                  <div className={classes.title} style={{ color: familyColor }}>
                    {member.arabicName}
                    {parseInt(count) > 0 && (
                      <div>
                        ({count ? toArabicDigits(count.toString()) : null})
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={classes.title} style={{ color: familyColor }}>
                    {member.name}
                    {parseInt(count) > 0 && <div>({count})</div>}
                  </div>
                )
              }
              className={`${classes.cardHeader} ${
                isArabic ? classes.arabicStyles : ""
              }`}
            />
            {member.children && member.children.length ? (
              <CardActions
                onClick={(e) => {
                  e.stopPropagation();
                  setExpanded(!expanded);
                }}
              >
                {expanded ? (
                  <ExpandLessIcon id={`${member.id}card`} />
                ) : (
                  <ExpandMoreIcon id={`${member.id}card`} />
                )}
              </CardActions>
            ) : null}
            <div className={classes.actions} id="actions">
              <InfoOutlinedIcon
                className={classes.addButton}
                onClick={() => {
                  setOpenAddDialog(true);
                }}
              />
            </div>
          </Card>
        </>
      }
    >
      {expanded
        ? member?.children
            ?.sort((a: FamilyMember, b: FamilyMember) => b.id - a.id)
            .map((c: FamilyMember) => {
              return (
                <Node
                  memberId={c.id}
                  setData={setData}
                  isArabic={isArabic}
                  ids={ids}
                  setId={setId}
                  editUser={editUser}
                  length="1"
                  lineage={`${member.name} ${lineage}`}
                  arabicLineage={`${member.arabicName} ${arabicLineage} `}
                  key={c.id}
                  color={familyColor}
                  branch={branch}
                />
              );
            })
        : null}
    </TreeNode>
  ) : (
    <TreeNode label={<CircularProgress />} />
  );
};

export default Node;
