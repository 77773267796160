import React from "react";
import {
  makeStyles,
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  TextField,
  Checkbox,
  Button,
  Modal,
  Box,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import { useAuthState } from "../context";

import { FamilyMember } from "types";
import axios from "axios";

const styles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 48,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0",
    },
    "& .PrivateSwitchBase-root-9": {
      padding: "2px 9px",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiDialogContent-root": {
        padding: "8px 4px",
      },
      "& .MuiDialog-paper": {
        margin: 0,
      },
    },
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 16px",
    minWidth: 300,
  },
  title: {
    "& div": {
      display: "flex",
      justifyContent: "center",
      fontWeight: 700,
      position: "relative",
    },
  },
  name: {
    fontSize: 24,
  },
  lineage: {
    fontSize: 14,
  },
  tableWrapper: {
    border: "1px solid #f2f2f2",
  },
  tableHeader: {
    display: "flex",
    justifyContent: "center",
    padding: 8,
    borderBottom: "1px solid #f2f2f2",
  },
  tableChildren: {
    maxHeight: 400,
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {},
  },
  child: {
    padding: "8px 16px",
    borderBottom: "1px solid #f2f2f2",
  },
  childName: {
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
  },
  childLineage: {
    fontSize: 12,
    display: "flex",
    justifyContent: "center",
  },
  accordionSamer: {
    borderTopLeftRadius: "0",
    borderTopRightRadius: "0",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    boxShadow: "none",
  },
  accordionButton: {
    backgroundColor: "#00cc66",
    color: "white",
    "& .MuiAccordionSummary-content": {
      justifyContent: "center",
    },
  },
  accordionDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 2px 16px",
      "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
        fontSize: 14,
      },
    },
  },
  textField: {
    width: 140,
    marginRight: 8,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  checkbox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: 16,
    fontWeight: 600,
  },
  checkboxEdit: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 600,
  },
  button: {
    backgroundColor: "#00cc66",
    color: "white",
    minWidth: "100%",
    marginTop: 8,
    padding: "4px !important",
    fontSize: 16,
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "#00cc00",
    },
  },
  closeButton: {
    border: "1px solid #f2f2f2",
    margin: "8px 0",
    fontSize: 16,
    fontWeight: 700,
  },
  checkIcon: {
    cursor: "pointer",
    color: "#00cc66",
  },
  noChildren: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    fontWeight: 700,
  },
  editButton: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    right: -120,
    color: "#00cc66",
    border: "1px solid #00cc66",
  },
  deleteButton: {
    display: "flex",
    alignItems: "center",
    padding: "2px 8px 2px 4px",
    marginTop: 8,
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
  editInput: {
    margin: "8px 0",
  },
  submitButton: {
    backgroundColor: "#00cc00",
    color: "white",
    fontSize: 16,
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "#00cc00",
      color: "white",
    },
  },
  confirmDelete: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  confirmDeleteContent: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  confirmDeleteButton: {
    display: "flex",
    alignItems: "center",
    padding: "2px 8px 2px 4px",
    marginBottom: 8,
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
  nameLink: {
    display: "flex",
    alignItems: "center",
    color: "inherit",
    textDecoration: "none",
    "& svg": {
      height: 20,
      alignSelf: "flex-end",
      paddingBottom: 6,
    },
  },
}));

interface OwnProps {
  open: boolean;
  member: FamilyMember;
  name: string;
  lineage?: string;
  arabicLineage: string;
  isArabic: boolean;
  setOpen: (open: boolean) => void;
  setData: (
    member: {
      name: string;
      arabicName: string;
      canEdit: boolean;
      canAdd: boolean;
      lineage: string;
    },
    parentId: number | string
  ) => void;
  editUser: (
    id: number | string,
    member: {
      name: string;
      arabicName: string;
      canEdit: boolean;
      canAdd: boolean;
      lineage: string;
    }
  ) => void;
  branch: string;
}

type Props = OwnProps;

const Profile = (props: Props) => {
  const classes = styles();
  const {
    open,
    member,
    name,
    lineage,
    arabicLineage,
    isArabic,
    setOpen,
    setData,
    editUser,
    branch,
  } = props;
  const [childName, setChildName] = React.useState<string>("");
  const [arabicName, setArabicName] = React.useState<string>("");
  const [isFemale, setIsFemale] = React.useState<boolean>(false);
  const [nameChanged, setNameChanged] = React.useState<boolean>(false);
  const [openEdit, setOpenEdit] = React.useState<boolean>(false);
  const [memberName, setMemberName] = React.useState<string>(member.name);
  const [editedArabicName, setEditedArabicName] = React.useState<string>("");
  const [canEdit, setCanEdit] = React.useState<boolean>(false);
  const [canDelete, setCanDelete] = React.useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);

  const userDetails = useAuthState();

  const { user = {} } = userDetails;

  const handleSubmit = () => {
    setData(
      {
        name: childName,
        arabicName,
        canAdd: !isFemale,
        canEdit: true,
        lineage: `${member.name} ${lineage} Al Ramahi`,
      },
      member.id
    );
    setChildName("");
    setArabicName("");
    setIsFemale(false);
  };

  const handleEdit = () => {
    editUser(member.id, {
      name: memberName,
      arabicName: editedArabicName,
      canEdit: member.canEdit,
      canAdd: !isFemale,
      lineage: member.lineage,
    });
    setNameChanged(false);
    setOpenEdit(false);
  };

  React.useEffect(() => {
    setMemberName(member.name);
  }, [open, isArabic]);
  React.useEffect(() => {
    const canAddBool =
      member.canAdd === null ? Boolean(member.canAdd) : !member.canAdd;

    if (
      isArabic &&
      name === editedArabicName &&
      member.name === memberName &&
      canAddBool === isFemale
    ) {
      return setNameChanged(false);
    } else if (
      name === memberName &&
      member.arabicName === editedArabicName &&
      canAddBool === isFemale
    ) {
      return setNameChanged(false);
    } else {
      return setNameChanged(true);
    }
  }, [memberName, editedArabicName, isFemale]);
  React.useEffect(() => {
    setEditedArabicName(member.arabicName);
    setIsFemale(
      member.canAdd === null ? Boolean(member.canAdd) : !member.canAdd
    );
  }, [member]);

  React.useEffect(() => {
    if (
      [branch + "Admin", "superAdmin"].indexOf(
        user?.role?.name
      ) > -1
    )
      setCanEdit(true);
    else setCanEdit(false);
    if (user?.role?.name === "superAdmin") {
      setCanDelete(true);
    } else {
      setCanDelete(false);
    }
  }, [user]);

  console.log(branch)

  const handleDelete = () => {
    const ROOT_URL = "https://sheltered-ridge-72729.herokuapp.com";
    axios
      .delete(`${ROOT_URL}/families/${member.id}`, {
        headers: {
          Authorization: `Bearer ${userDetails?.token}`,
        },
      })
      .then(() => setConfirmDelete(false));
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} className={classes.root}>
      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogTitle className={classes.title}>
          {isArabic ? "تعديل" : "Edit User"}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextField
            value={memberName}
            label={isArabic ? "الاسم الانجليزي" : "English Name"}
            variant="outlined"
            onChange={(e: any) => setMemberName(e.target.value)}
            className={classes.editInput}
          />
          <TextField
            value={editedArabicName}
            label={isArabic ? "الاسم" : "Arabic Name"}
            variant="outlined"
            onChange={(e: any) => setEditedArabicName(e.target.value)}
            className={classes.editInput}
          />
          <div className={classes.checkboxEdit}>
            {isArabic ? "انثى؟" : "Female"}
            <Checkbox
              size="small"
              checked={isFemale}
              onChange={() => {
                setIsFemale(!isFemale);
              }}
            />
          </div>
          <Button
            onClick={handleEdit}
            disabled={
              memberName?.length <= 2 ||
              editedArabicName?.length < 2 ||
              !nameChanged
            }
            className={classes.submitButton}
          >
            {isArabic ? "تثبيت" : "Submit"}
          </Button>
          <Button
            onClick={() => setOpenEdit(false)}
            className={classes.closeButton}
          >
            {isArabic ? "اغلاق" : "Close"}
          </Button>
        </DialogContent>
      </Dialog>
      <DialogTitle className={classes.title}>
        <div className={classes.name}>
          {member.canEdit !== false ? (
            <div>
              <div>
                <a
                  href={`/tree/${member.id}`}
                  target="_blank"
                  className={classes.nameLink}
                >
                  <AccountTreeIcon />
                  {name}
                </a>
                {canDelete && `- ${member?.id}`}
              </div>
              {canEdit && (
                <div>
                  <Button
                    className={classes.editButton}
                    variant="outlined"
                    onClick={() => setOpenEdit(true)}
                  >
                    {isArabic ? "تعديل" : "Edit"}
                    <EditIcon />
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div>
              <a
                href={`/tree/${member.id}`}
                target="_blank"
                className={classes.nameLink}
              >
                <AccountTreeIcon />
                {name}
              </a>
              {canDelete && `- ${member?.id}`}
            </div>
          )}
        </div>
        <div className={classes.lineage}>
          {isArabic ? arabicLineage : lineage}{" "}
          {isArabic ? "الرمحي" : "Al-Ramahi"}
        </div>
        {canDelete && (
          <div>
            <Button
              className={classes.deleteButton}
              variant="outlined"
              onClick={() => setConfirmDelete(true)}
            >
              <DeleteForeverIcon />
              Delete
            </Button>
          </div>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid xs={12} className={classes.tableWrapper}>
          {member?.children?.length > 0 ||
          member?.canAdd ||
          member?.canAdd === null ? (
            <>
              <Grid xs={12} className={classes.tableHeader}>
                {isArabic ? "الاولاد" : "Children"}
              </Grid>
              <Grid className={classes.tableChildren}>
                {member?.children.map((child: FamilyMember) => (
                  <Grid
                    className={classes.child}
                    key={child.id + (lineage || "")}
                  >
                    <div className={classes.childName}>
                      {isArabic ? child.arabicName : child.name}
                    </div>
                    <div className={classes.childLineage}>
                      {isArabic
                        ? member.arabicName + " " + arabicLineage + "الرمحي"
                        : member.name + " " + lineage + "Al-Ramahi"}
                    </div>
                  </Grid>
                ))}
                {member.children.length === 0 &&
                  (member.canAdd || member.canAdd === null) && (
                    <Grid className={classes.noChildren}>
                      No Children added.
                      {/* Press the button below to add. */}
                    </Grid>
                  )}
              </Grid>
            </>
          ) : null}
          {member.canAdd === null || member.canAdd ? (
            <Grid>
              {canEdit && (
                <Accordion className={classes.accordionSamer}>
                  <AccordionSummary className={classes.accordionButton}>
                    {isArabic ? "اضافة الابناء" : "Add Child"}
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <TextField
                      id="outlined-basic"
                      label={isArabic ? "الاسم الانجليزي" : "English Name"}
                      variant="outlined"
                      size="small"
                      value={childName}
                      onChange={(e) => setChildName(e.target.value)}
                      className={classes.textField}
                    />
                    <TextField
                      id="outlined-basic"
                      label={isArabic ? "الاسم" : "Arabic Name"}
                      variant="outlined"
                      size="small"
                      value={arabicName}
                      onChange={(e) => setArabicName(e.target.value)}
                      className={classes.textField}
                    />
                    <div className={classes.checkbox}>
                      {isArabic ? "انثى؟" : "Female"}
                      <Checkbox
                        size="small"
                        checked={isFemale}
                        onChange={() => setIsFemale(!isFemale)}
                      />
                    </div>
                    <Button
                      aria-label="add"
                      onClick={() => handleSubmit()}
                      className={classes.button}
                      disabled={childName.length < 3 || arabicName.length < 2}
                      size="small"
                    >
                      {isArabic ? "تثبيت" : "Confirm"}
                    </Button>
                  </AccordionDetails>
                </Accordion>
              )}
            </Grid>
          ) : null}
        </Grid>
        <Button onClick={() => setOpen(false)} className={classes.closeButton}>
          {isArabic ? "اغلاق" : "Close"}
        </Button>
      </DialogContent>
      <Modal
        open={confirmDelete}
        className={classes.confirmDelete}
        onClose={() => {
          setConfirmDelete(false);
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          padding={4}
          className={classes.confirmDeleteContent}
        >
          <Box display="flex" flexDirection="column" marginBottom={2}>
            Are you sure you want to delete?{" "}
            <strong>This action cannot be reversed.</strong>
          </Box>
          <Button
            className={classes.confirmDeleteButton}
            onClick={handleDelete}
          >
            Submit
          </Button>
          <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
        </Box>
      </Modal>
    </Dialog>
  );
};

export default Profile;
