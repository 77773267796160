import React from 'react';
import { makeStyles, Theme } from "@material-ui/core";

const styles = makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "100vw",
    },
    routeList: {
        display: "flex",
        width: "40%",
        flexDirection: "row",
        listStyle: "none",
        alignItems: "space-around",
        justifyContent: "space-between"
    }
  }));

  interface OwnProps {
    bookName: String
  }

  type Props = OwnProps;

function DisplayPDF(props: Props) {
    const {bookName } = props;
    const classes=styles();

    return (
        <div className={classes.root}>
            <object data={`/pdfs/${bookName}.pdf`} type="application/pdf" width="100%" height="100%"></object>
        </div>
    )
}

export default DisplayPDF;
