import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "components/Home";
import FamilyTree from "components/FamilyTree";
import SingleFamilyTree from "components/SingleFamilyTree";
import DisplayPDF from "components/DisplayPDF";
import { makeStyles, Theme } from "@material-ui/core";
import { AuthProvider } from "./context";
import "./app.css";

const styles = makeStyles((theme: Theme) => ({
  root: {
    backgroundImage: "url('/ep_naturalblack.png')",
    paddingBottom: 350,
    overflowX: "auto",
    color: "white",
    width: "100%",
  },
}));

function App() {
  const classes = styles();
  return (
    <AuthProvider>
      <div className={classes.root}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/tree">
              <FamilyTree />
            </Route>
            <Route exact path="/tree/:id">
              <SingleFamilyTree />
            </Route>
            <Route path="/AlMuzayria">
              <DisplayPDF bookName="AlMuzayria"/>
            </Route>
            <Route path="/DaysFromMyLife">
              <DisplayPDF bookName="DaysFromMyLife"/>
            </Route>
          </Switch>
        </Router>
      </div>
    </AuthProvider>
  );
}

export default App;
